<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title class="d-flex">
          Workouts By Month in {{ currentYear }}
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                class="ml-auto"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item-group v-model="currentYear">
                <v-list-item
                  v-for="(year,i) in yearList"
                  :key="year"
                  :value="year"
                >
                  <v-list-item-title>
                    <span>{{ year }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-card-title>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">
                    Sport
                  </th>
                  <th class="text-uppercase" v-for="index in 12">
                    {{ monthName(index) }}
                  </th>
                  <th class="text-uppercase text-right">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in workouts_by_month"
                  :key="index"
                  v-show="item.stats.total > 0"
                >
                  <td>
                    <span class="badge-sm" :style="`background-color: ${item.sport.label_color}`"></span>
                    {{ item.sport.name }}
                  </td>
                  <td class="text-uppercase" v-for="mon in 12">
                    {{ item.stats[mon] }}
                  </td>
                  <td class="text-uppercase text-right">
                    <strong>{{item.stats.total}}</strong>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th class="text-uppercase">
                    TOTAL
                  </th>
                  <th class="text-uppercase" v-for="item in totals.months">
                    {{ item.total }}
                  </th>
                  <th class="text-uppercase text-right">
                    {{ totals.workouts }}
                  </th>
                </tr>
                <tr>
                  <th class="text-uppercase">
                    Days in month
                  </th>
                  <th class="text-uppercase" v-for="item in totals.months">
                    {{ item.days }}
                  </th>
                  <th class="text-uppercase text-right">
                    {{ totals.days }}
                  </th>
                </tr>
                <tr>
                  <th class="text-uppercase">
                    % total/days
                  </th>
                  <th class="text-uppercase" v-for="item in totals.months">
                    {{ item.percent }}
                  </th>
                  <th class="text-uppercase text-right">
                    {{ totals.percent }}
                  </th>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
      </v-card>
    </v-col>
    <v-col cols="12" md="8">
      <v-card>
        <v-card-title>
          Workouts accumulation through month in {{ currentYear }}
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                class="ml-auto"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item-group v-model="currentYear">
                <v-list-item
                  v-for="(year,i) in yearList"
                  :key="year"
                  :value="year"
                >
                  <v-list-item-title>
                    <span>{{ year }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-card-title>
        <vue-apex-charts
          id="sport-month-spread-chart"
          height="400"
          ref="refMonthChart"
          :options="chartOptions"
          :series="chartData"
        ></vue-apex-charts>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card>
        <v-card-title>
          Sport ratio in year {{ currentYear }}
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                class="ml-auto"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item-group v-model="currentYear">
                <v-list-item
                  v-for="(year,i) in yearList"
                  :key="year"
                  :value="year"
                >
                  <v-list-item-title>
                    <span>{{ year }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <vue-apex-charts
            type="donut"
            height="400"
            ref="refDonutChart"
            :options="donutChart.chartOptions"
            :series="donutChart.series"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title class="d-flex">
          Length in km By Month in {{ currentYear }}
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                class="ml-auto"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item-group v-model="currentYear">
                <v-list-item
                  v-for="(year,i) in yearList"
                  :key="year"
                  :value="year"
                >
                  <v-list-item-title>
                    <span>{{ year }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-card-title>
          <v-simple-table dense class="lengths mb-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">
                    Sport
                  </th>
                  <th class="text-uppercase" v-for="index in 12">
                    {{ monthName(index) }}
                  </th>
                  <th class="text-uppercase text-right">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in lengths"
                  :key="index"
                  v-show="item.stats.total > 0"
                >
                  <td>{{ item.sport.name }}</td>
                  <td class="text-uppercase" v-for="mon in 12">
                    {{ item.stats[mon].toFixed(2) }}
                  </td>
                  <td class="text-uppercase text-right">
                    <strong>{{item.stats.total.toFixed(2)}}</strong>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-card-text align="right">
            <v-btn-toggle
              v-model="lineChartSportId"
            >
              <v-btn v-for="sport in lineChartSports" :value="sport.id">
                {{ sport.name }}
              </v-btn>
            </v-btn-toggle>
          </v-card-text>

          <vue-apex-charts
            type="line" height="300" :options="lineChartOptions" :series="lineChartData" ref="refLineChart"
          />
      </v-card>
    </v-col>
    <v-col cols="12">
      <stats-locations :year-list="yearList" :current-year="currentYear" @update-current-year="updateCurrentYear"></stats-locations>
    </v-col>
    <v-col cols="12" md="6">
      <stats-sport-by-year></stats-sport-by-year>
    </v-col>
    <v-col cols="12" md="6">
      <stats-length-by-year></stats-length-by-year>
    </v-col>

  </v-row>
</template>

<script>
import {
  mdiDotsVertical,
} from '@mdi/js'
import { ref, onUnmounted, computed, watch } from '@vue/composition-api'
import store from '@/store'
import calendarStoreModule from './calendarStoreModule'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'

import StatsSportByYear from './StatsSportByYear.vue'
import StatsLengthByYear from './StatsLengthByYear.vue'
import StatsLocations from './StatsLocations.vue'

export default {
  components: {
    VueApexCharts,
    StatsSportByYear,
    StatsLengthByYear,
    StatsLocations
  },
  setup() {

    const $vuetify = getVuetify()

    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const CALENDAR_APP_STORE_MODULE_NAME = 'app-calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const monthName = index => {
      return moment.monthsShort(index - 1)
    }

    const params = []
    const workouts_by_month = ref({})
    const refMonthChart = ref(null)
    const refDonutChart = ref(null)
    const refLineChart = ref(null)
    const totals = ref({})
    const lengths = ref({})
    const yearList = ["2025","2024","2023","2022","2021","2020","2019","2018","2017"]
    const currentYear = ref(moment().format('YYYY'))
    const lineChartSportId = ref(null)
    const lineChartSports = ref([])

    const fetchStats = (params) => {

      store
        .dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/fetchStats`,params)
        .then(response => {
          workouts_by_month.value = response.data.sports
          totals.value = response.data.totals
          lengths.value = response.data.lengths

          chartData.length = 0
          chartOptions.colors.length = 0
          chartOptions.xaxis.categories.length = 0

          donutChart.chartOptions.colors.length = 0
          donutChart.chartOptions.labels.length = 0
          donutChart.series.length = 0

          lineChartOptions.colors.length = 0
          lineChartData.length = 0

          lineChartSports.value.length = 0

          for (var i = 0; i < workouts_by_month.value.length; i++) {

            if (workouts_by_month.value[i].stats.total > 0) {

              chartOptions.colors.push(workouts_by_month.value[i].sport.label_color)

              let arr = [];
              for (var j = 1; j <= 12; j++) {
                arr.push(workouts_by_month.value[i].stats[j])
              }

              chartData.push({
                name: workouts_by_month.value[i].sport.name,
                data: arr
              })

              donutChart.chartOptions.colors.push(workouts_by_month.value[i].sport.label_color)
              donutChart.chartOptions.labels.push(workouts_by_month.value[i].sport.name)
              donutChart.series.push(workouts_by_month.value[i].stats.total)
            }

          }

          for (var i = 1; i <= 12; i++) {
            chartOptions.xaxis.categories.push(monthName(i))
            lineChartOptions.xaxis.categories.push(monthName(i))
          }

          refMonthChart.value.updateSeries(chartData)
          refMonthChart.value.updateOptions(chartOptions)
          refDonutChart.value.updateSeries(donutChart.series)
          refDonutChart.value.updateOptions(donutChart.chartOptions)

          for (var key in lengths.value) {

            if (key.match("_len")) {
              if (lengths.value[key].stats.total > 0) {
                lineChartSports.value.push({
                  id: lengths.value[key].sport.sport_id,
                  name: lengths.value[key].sport.name
                })
              }
            }

          }

          for (var key in lengths.value) {

            if (key.match("_len")) {

              lineChartSportId.value = key.replace("_len","")

              lineChartOptions.colors.push(lengths.value[key].sport.label_color)

              let arr = [];
              for (var j = 1; j <= 12; j++) {
                arr.push(lengths.value[key].stats[j])
              }

              lineChartData.push({
                name: lengths.value[key].sport.name,
                data: arr
              })
              break;

            }
          }

          refLineChart.value.updateSeries(lineChartData)
          refLineChart.value.updateOptions(lineChartOptions)

        })
        .catch(error => {
          console.error(error)
          console.error(error.response)
        })
    }

    const fetchYearStats = (params) => {

      store
        .dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/fetchStats`,params)
        .then(response => {


        })
        .catch(error => {
          console.error(error)
          console.error(error.response)
        })
    }

    const chartOptions = {
      colors: [],
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      noData: {
        text: 'Loading...'
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '35%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: value => kFormatter(value, 0),
        },
      },
      grid: {
        strokeDashArray: 7,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 6,
        lineCap: 'round',
        colors: ['#fff'],
      },
      responsive: [
        {
          breakpoint: 1400,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '45%',
              },
            },
          },
        },
      ],
    }

    const chartData = []

    const donutChart = {
      series: [],
      chartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        colors: [],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        noData: {
          text: 'Loading...'
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {

              },
            },
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
    }

    const lineChartOptions = {
      chart: {
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {

      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: 'km (kilometers)'
        },
        labels: {
          formatter: value => value.toFixed(0),
        },
      },
      colors: [],
      legend: {
        show: false,
      },
    }

    const lineChartData = []

    fetchStats({year : currentYear.value})

    watch(currentYear, () => {
      fetchStats({year : currentYear.value})
    })

    watch(lineChartSportId, () => {

      lineChartOptions.colors.length = 0
      lineChartData.length = 0

      lineChartOptions.colors.push(lengths.value[lineChartSportId.value + "_len"].sport.label_color)

      let arr = [];
      for (var j = 1; j <= 12; j++) {
        arr.push(lengths.value[lineChartSportId.value + "_len"].stats[j])
      }

      lineChartData.push({
        name: lengths.value[lineChartSportId.value + "_len"].sport.name,
        data: arr
      })

      refLineChart.value.updateSeries(lineChartData)
      refLineChart.value.updateOptions(lineChartOptions)
    })

    const updateCurrentYear = (year) => {
      currentYear.value = year
    }

    return {
      workouts_by_month,
      monthName,
      totals,
      lengths,
      yearList,
      currentYear,
      chartData,
      chartOptions,
      donutChart,
      refMonthChart,
      refDonutChart,
      refLineChart,
      lineChartOptions,
      lineChartData,
      lineChartSportId,
      lineChartSports,
      updateCurrentYear,
      icons: {
        mdiDotsVertical
      }
    }
  },
}
</script>

<style lang="scss">
  .lengths {
    tbody {
      tr {
        &:nth-child(3n-2) {
          background: #eee;
        }
      }
    }
  }
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    vertical-align: -2px;
  }
</style>
