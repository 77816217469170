<template>
  <v-card id="invoice-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <!-- actions -->
        <v-select
          v-model="selectedAction"
          label="Actions"
          single-line
          outlined
          dense
          :items="actions"
          hide-details
          :disabled="Boolean(!selectedTableData.length)"
          class="invoice-list-actions me-3"
        ></v-select>

        <!-- create invoice -->
        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'apps-invoice-add' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Invoice</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <v-autocomplete
          v-model="customerFilter"
          outlined
          :menu-props="{ offsetY: true }"
          :items="customers"
          dense
          hide-details
          clearable
          :filter="customerAutocompleteFilter"
          item-text="company_name"
          item-value="id"
          placeholder="Select Customer"
          class="invoice-list-customer me-3"
        ><template slot="selection" slot-scope="data">
          {{ data.item.company_name }} (<small>{{ data.item.contact_person }}</small>)
        </template>
        <template slot="item" slot-scope="data">
          {{ data.item.company_name }} (<small>{{ data.item.contact_person }}</small>)
        </template></v-autocomplete>

        <v-select
          v-model="yearFilter"
          :items="yearOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Select Year"
          class="invoice-list-status me-3"
        ></v-select>

        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Select Status"
          class="invoice-list-status"
        ></v-select>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="invoiceListTable"
      :options.sync="options"
      :server-items-length="totalInvoiceListTable"
      :loading="loading"
      show-select
      class="text-no-wrap"
      :footer-props="{'items-per-page-options':[25, 50, 100]}"
    >
      <!-- trending header -->
      <template #[`header.trending`]>
        <v-icon size="22">
          {{ icons.mdiTrendingUp }}
        </v-icon>
      </template>

      <!-- id -->
      <template #[`item.id`]="{item}">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'apps-invoice-edit', params: { id: item.id } }"
        >
          {{ item.invoice_number }}/{{ item.invoice_year }}
        </router-link>
      </template>

            <!-- client -->
      <template #[`item.customer`]="{item}">
        <div class="d-flex align-center">

          <div class="d-flex flex-column">
            <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.customer.company_name }}</span>
            <span class="text-xs">{{ item.customer.contact_person }}</span>
          </div>
        </div>
      </template>

      <!-- total -->
      <template #[`item.total`]="{item}">
        {{ item.total.toLocaleString(undefined, { minimumFractionDigits: 2 }) }} €
      </template>

      <!-- Balance -->
      <template #[`item.status`]="{item}">
        <span v-if="checkType(item.paid) !== 'number'">
          {{ item.paid }}
        </span>

        <!-- chip -->
        <v-chip
          v-else
          small
          :color="item.paid == 1 ? 'success':'error'"
          :class="`v-chip-light-bg ${item.paid == 1 ? 'success':'error'}--text font-weight-semibold`"
          @click="changeInvoiceStatus(item)"
        >
          {{ item.paid == 1 ? 'Paid':'Unpaid' }}
        </v-chip>
      </template>

      <!-- date -->
      <template #[`item.issuedDate`]="{item}">
        <span class="text-no-wrap">{{ item.billing_date }}</span>
      </template>

      <template #[`item.dueDate`]="{item}">
        <span class="text-no-wrap">{{ item.due_date }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{ name: 'apps-invoice-edit', params: { id: item.id } }"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="downloadSingle(item.id)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiDownloadOutline }}
                  </v-icon>
                  <span>Download</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="duplicateInvoice(item.id)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                  <span>Duplicate</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="deleteInvoice(item.id)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template slot="body.append">
        <tr class="">
            <th class="title">Totals</th>
            <th></th>
            <th></th>
            <th class="title text-right">{{ sumField('total').toLocaleString(undefined, { minimumFractionDigits: 2 }) }} €</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
      </template>
    </v-data-table>
    <confirm ref="confirm"></confirm>
  </v-card>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiDownloadOutline,
  mdiPencilOutline,
  mdiContentCopy,
} from '@mdi/js'

import { onUnmounted, ref, watch } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Confirm from '@/components/Confirm.vue'
import store from '@/store'

// store module
import invoiceStoreModule from '../invoiceStoreModule'

// composition function
import useInvoicesList from './useInvoiceList'

export default {
  components: {
    Confirm
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      invoiceListTable,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      fetchInvoices,
      yearFilter,
      customerFilter,
      totalInvoiceListTable,
      loading,
      selectedTableData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    const statusOptions = ref(['Paid', 'Unpaid'])
    const yearOptions = ref(["2025","2024","2023","2022","2021","2020","2019","2018","2017"])

    const actions = ['Delete', 'Download']

    const selectedAction = ref('')

    const sumField = (key) => {
        return invoiceListTable.value.reduce((a, b) => a + (b[key] || 0), 0)
    }

    const actionOptions = [
      { title: 'Download', icon: mdiDownloadOutline, type: "link" },
      { title: 'Edit', icon: mdiPencilOutline, type: "link" }
    ]

    const checkType = data => {
      if (typeof data === 'number') return 'number'
      if (typeof data === 'string') return 'string'

      return 0
    }

    const confirm = ref(null)

    const deleteInvoice = (id) => {
      confirm.value.open('Delete confirmation', 'Are you sure you want to delete this invoice?').then((confirm) => {
        if (confirm) {
          store.dispatch('app-invoice/deleteInvoice', id).then(() => {
            store.dispatch("addNotification",{message:"Invoice was removed.",type: "success"});
            fetchInvoices()
          }).catch((error) => {
            console.log(error)
          })
        }
      })
    }

    const duplicateInvoice = (id) => {
      loading.value = true
      store
        .dispatch('app-invoice/duplicateInvoice', id )
        .then(response => {
          loading.value = false
          store.dispatch("addNotification",{message:"Invoice was duplicated.",type: "success"});
          fetchInvoices()
        })
        .catch(error => {
          console.log(error)
        })
    }

    const changeInvoiceStatus = (item) => {
      loading.value = true
      item.paid = (item.paid == 1) ? 0 : 1
      store
        .dispatch('app-invoice/updateInvoice', item)
        .then(response => {
          loading.value = false
          store.dispatch("addNotification",{message:"Invoice status was changed.",type: "success"});
        })
        .catch(error => {
          console.log(error)
        })
    }

    const customers = ref([])
    store.dispatch('app-invoice/fetchClients').then(response => {
      customers.value = response.data
    })

    const customerAutocompleteFilter = (item,queryText,itemText) => {
      return (
        item.company_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.contact_person.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    }

    watch(selectedTableData, () => {
      selectedAction.value = ""
    })

    watch(selectedAction, () => {
      if (selectedAction.value == "Delete" && selectedTableData.value.length) {
        confirm.value.open('Delete confirmation', 'Are you sure you want to delete selected invoices?').then((confirm) => {
          if (confirm) {
            for (var i = 0; i < selectedTableData.value.length; i++) {
              store.dispatch('app-invoice/deleteInvoice', selectedTableData.value[i].id).then(() => {

              }).catch((error) => {
                console.log(error)
              })
            }
            store.dispatch("addNotification",{message:"Invoices were removed.",type: "success"});
            selectedTableData.value = []
            selectedAction.value = ''
            fetchInvoices()
          }
        })
      }

      if (selectedAction.value == "Download" && selectedTableData.value.length) {
        loading.value = true
        let ids = []
        for (var i = 0; i < selectedTableData.value.length; i++) {
          ids.push(selectedTableData.value[i].id)
        }

        downloadPdf(ids)
      }
    })

    const downloadSingle = (id) => {
      loading.value = true
      let ids = [id]
      downloadPdf(ids)
    }

    const downloadPdf = (ids) => {
      store
        .dispatch('app-invoice/fetchPdf', ids)
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "invoice"
          link.click()
          URL.revokeObjectURL(link.href)
          loading.value = false
          selectedAction.value = ""
        })
        .catch(error => {
          console.log(error)
        })
    }

    return {
      tableColumns,
      searchQuery,
      statusOptions,
      statusFilter,
      options,
      totalInvoiceListTable,
      sumField,
      invoiceListTable,
      loading,
      actions,
      selectedTableData,
      actionOptions,
      yearOptions,
      yearFilter,
      selectedAction,
      customers,
      customerFilter,
      customerAutocompleteFilter,
      checkType,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      duplicateInvoice,
      confirm,
      deleteInvoice,
      downloadSingle,
      changeInvoiceStatus,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiContentCopy,
        mdiDownloadOutline,
        mdiPencilOutline
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#invoice-list {
  .invoice-list-actions {
    max-width: 7.81rem;
  }
  .invoice-list-search {
    max-width: 10.625rem;
  }
  .invoice-list-status {
    max-width: 11.3rem;
  }
  .invoice-list-customer {
    max-width: 25rem;
  }
}
</style>
